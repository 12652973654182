/* src/styles/ziprenewal_b.css */
.body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.logo img {
  max-width: 200px;
  height: auto;
}

.navMenu {
  display: flex;
  gap: 20px;
}

.navMenu a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

/* CTA Button */
.ctaButton {
  padding: 10px 20px;
  background-color: #ff4500;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Hero Section */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f1;
  padding: 50px 20px;
  color: black;
  text-align: left;
}

.heroContent {
  flex: 1;
  padding: 0 15px;
  margin-bottom: 20px;
  text-align: center;
}

.heroContent h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.heroContent h2 {
  font-size: 22px;
  margin-bottom: 20px;
}

.heroContent p {
  font-size: 18px;
  margin-bottom: 30px;
}

.heroImage {
  flex: 1;
  padding-left: 20px;
}

.heroImage img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.primaryCta {
  background-color: #0d6efd; /* Unified primary blue */
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.primaryCta:hover {
  background-color: #0056b3; /* Darker blue for hover effect */
}

/* Value Proposition */
.valueProposition {
  display: flex;
  justify-content: space-around;
  padding: 0px 10px;
  background-color: #f1f1f1;
  text-align: center;
}

.valueItem {
  width: 30%;
}

.valueItem img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

/* Testimonial Section */
.testimonial {
  padding: 40px;
  background-color: #f8f9fa;
  text-align: center;
}

.testimonial p {
  font-size: 18px;
  margin-bottom: 20px;
}

.trustLogos {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.trustLogos img {
  width: 100px;
  height: auto;
}

/* Footer */
.footer {
  background-color: #343a40;
  color: #ffffff;
  text-align: center;
  padding: 20px;
}

.footer p {
  margin: 0;
  font-size: 0.9rem;
}

.footerLink {
  color: #0d6efd;
  text-decoration: none;
}

.footerLink:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modalContent {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.modalContent input {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submitButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #0056b3;
}

.closeButton {
  position: absolute;
  background-color: white;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}

.closeButton:hover {
  color: #ff0000;
  background-color: white;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .navMenu {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .logo {
    align-self: center;
  }
  .logo img {
    max-width: 150px; /* Limit the logo size in mobile */
    height: auto;
  }

  .hero {
    flex-direction: column;
    padding: 30px 10px;
    text-align: center;
  }

  .heroContent {
    padding: 0 15px;
  }

  .heroContent h1 {
    font-size: 32px;
  }

  .heroContent h2 {
    font-size: 20px;
  }

  .heroContent p {
    font-size: 16px;
  }

  .valueProposition {
    flex-direction: column;
    padding: 0 10px;
  }

  .valueItem {
    width: 100%;
    margin-bottom: 20px;
  }

  .trustLogos {
    flex-direction: column;
  }

  .trustLogos img {
    width: 80%;
    max-width: 100px;
  }

  footer {
    padding: 15px;
  }

  .modalContent {
    padding: 15px;
  }

  .modalContent input {
    width: 100%;
  }

  .submitButton {
    width: 100%;
  }

  .closeButton {
    top: 5px;
    right: 5px;
    font-size: 16px;
  }
}
