:root {
  --primary-blue-dark: #2a468e;
  --primary-blue-light: #4e65a1;
  --accent-yellow: #ffcc66;
}
.header {
  background: linear-gradient(
    to right,
    var(--primary-blue-dark),
    var(--primary-blue-light)
  );
  padding: 20px;
  color: white;
  text-align: center;

  border-bottom: 3px solid var(--accent-yellow);
}

.header h1 {
  margin: 0;
  font-size: 2.5rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.logo img {
  animation: fadeIn 5s ease-in;
  width: 400px;
  height: auto;
}

.tagline {
  font-size: 1.2em;
  color: #ffffff;
  margin-top: 10px;
  text-align: center;
  animation: fadeIn 2s ease-in 1s forwards;
  opacity: 0;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

nav ul li {
  display: inline;
  margin: 0 15px;
}

nav ul li a {
  color: white;
}
nav ul li a:hover {
  color: var(--accent-yellow);
  text-decoration: underline;
}

.hero {
  background-color: #ffffff;
  padding: 50px;
  text-align: center;
}

.hero h2 {
  font-size: 2.2rem;
  color: #0d6efd;
}

.hero p {
  font-size: 1.2rem;
  color: #555;
}

.inputCTA {
  font-size: 1rem;
  color: var(--primary-blue-dark);
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.inputCTA span {
  color: var(--accent-yellow);
  font-weight: bold;
}

.searchBar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 300px;
  margin: 0 auto;
}

.searchBar input[type="text"],
.searchBar input[type="email"] {
  width: 100%;
  padding: 10px;
  font-size: 1.1rem;
  border: 2px solid #2a468e;

  border-radius: 5px;
  outline: none;
}

.searchBar button {
  width: 50%;
  background-color: var(--primary-blue-dark);
  color: white;
  font-size: 1.1em;
  padding: 10px 20px;
  border: 2px solid var(--primary-blue-dark);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  animation: pulse 12s ease-in-out infinite;
}

.searchBar input[type="text"]:-webkit-autofill,
.searchBar input[type="email"]:-webkit-autofill {
  background-color: #8cb0ff !important; /* Set to white or your preferred color */
  color: #000000 !important; /* Set font color if necessary */
  transition: background-color 5000s ease-in-out 0s; /* Workaround for some browsers */
}

@keyframes pulse {
  0%,
  85% {
    opacity: 1;
  }
  90%,
  95% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.searchBar button:hover {
  background-color: #4e65a1;
  transform: scale(1.05);
  color: #ffcc66;
  border-color: #4e65a1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  animation: none;
}

.features {
  background-color: #f8f9fa;
  padding: 50px;
  text-align: center;
}

.feature {
  display: inline-block;
  width: 30%;
  margin: 20px;
}

.feature img {
  width: 100px;
  height: 100px;
}

.feature h3 {
  color: #0d6efd; /* Unified primary blue */
}

.feature p {
  margin-top: 10px;
  color: #666; /* Neutral gray */
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #343a40;
  color: #ffffff;
  padding: 10px;
  text-align: center;
}

.footer p {
  margin: 0;
  font-size: 0.9rem;
}

.thankyouCard {
  background-color: #ffffff; /* Bright background for contrast */
  padding: 30px;
  margin: 40px auto;
  max-width: 80%;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: var(--primary-blue-dark);
  border: 1px solid #e0e0e0;
}

.thankyouCard h2 {
  color: var(--primary-blue-dark);
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.thankyouCard p {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #555;
  margin-bottom: 20px;
}

.thankyouCard h3 {
  color: var(--primary-blue-light);
  font-size: 1.5rem;
  margin: 20px 0;
}

.thankyouCard ul {
  list-style-type: none;
  padding: 0;
}

.thankyouCard ul li {
  color: #333;
  margin: 10px 0;
  font-size: 1.1rem;
}

.thankyouCard button {
  background-color: var(--primary-blue-dark);
  color: white;
  padding: 12px 30px;
  font-size: 1.1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.thankyouCard button:hover {
  background-color: var(--primary-blue-light);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.thankyouCard button:focus {
  outline: none;
  border: 2px solid var(--accent-yellow);
}

.heroBanner {
  text-align: center;
  background-color: #f1f4fa;
  padding: 20px;
  color: white;
  border-radius: 8px;
  margin-bottom: 20px;
}

.nextSteps,
.additionalCTA,
.testimonials,
.resourceDownload,
.socialShare {
  margin-top: 20px;
}

.testimonials blockquote {
  font-style: italic;
  color: #555;
  /* border-left: 4px solid var(--accent-yellow); */
  padding-left: 15px;
  margin: 10px 0;
}

.resourceDownload {
  text-align: center;
}

.socialShare .socialIcons {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 10px;
}

.socialIcons img {
  width: 30px;
  height: 30px;
  transition: transform 0.2s ease-in-out;
}

.socialIcons img:hover {
  transform: scale(1.1);
}

.ctaButton {
  background-color: var(--primary-blue-dark);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.ctaButton:hover {
  background-color: var(--primary-blue-light);
}

.tm_image {
  margin-left: 20px; /* Space between text and image */
}

.tm_image img {
  max-width: 200px;
  height: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dims the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it appears above all other elements */
}

.modalContent {
  position: relative;
  background-color: #fff; /* White background for the modal content */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for a floating effect */
  max-width: 400px;
  width: 100%; /* Responsive width */
  text-align: center;
}

.modalContent input {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submitButton {
  padding: 10px 20px;
  background-color: var(--primary-blue-dark);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: var(--primary-blue-light);
}

.closeButton {
  position: absolute;
  background-color: white;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}

.spinner {
  position: absolute;
  top: 20%;
  left: 40%;
  transform: translate(-50%, -50%);
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #0d6efd;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.closeButton:hover {
  color: #ff0000;
  background-color: white;
}

.video {
  width: 25%;
  height: auto;
  margin: 0 auto;
  display: block;
}

/* Mobile  */

@media (max-width: 768px) {
  .header {
    padding: 15px;
  }

  .header h1 {
    font-size: 1.5rem;
    /* width: auto; */
  }

  .logo img {
    animation: fadeIn 5s ease-in;
    width: 80%;
    height: auto;
  }

  .tagline {
    font-size: 1em;
  }

  nav ul {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  nav ul li {
    margin: 10px 0;
  }

  /* .hero {
    padding: 30px;
  } */

  .hero,
  .CTA {
    padding: 20px;
    text-align: center;
  }

  .CTA h2,
  .thankyouCard h2 {
    font-size: 1.5rem;
  }

  .CTA p,
  .inputCTA {
    font-size: 1rem;
  }

  .hero h2 {
    font-size: 1.8rem;
  }

  .hero p,
  .CTA p,
  .thankyouCard p {
    font-size: 1rem;
    line-height: 1.4;
  }

  /* .searchBar {
    flex-direction: column;
    margin-top: 20px;
  } */

  .searchBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  /* .searchBar input[type="text"] {
    width: 80%;
    margin-bottom: 10px;
    align-self: center;
    border-radius: 5px;
    border-right: 2px solid #2a468e;
  } */

  .searchBar input[type="text"],
  .searchBar input[type="email"],
  .searchBar button {
    width: 100%;
    max-width: 400px;
  }

  .searchBar button {
    width: 80%;
    align-self: center;
  }

  .searchBar .submitButton {
    padding: 12px 20px;
    font-size: 1rem;
  }

  .ctaButton {
    padding: 12px 20px;
    font-size: 1rem;
  }

  .features {
    padding: 30px;
  }

  .feature {
    width: 100%;
    margin: 10px 0;
    text-align: center;
  }

  .footer {
    padding: 20px;
  }

  .footer p {
    font-size: 0.8rem;
    text-align: center;
  }
}
